import React from "react"
import { Fade } from "react-reveal"

const TwoColumnParagraph: React.FC<any> = (item) => {
  return (
    <div className="c-twoColumnParagraph rellax" data-rellax-speed="-0.3" data-rellax-xs-speed="0" data-rellax-mobile-speed="0">
      {item &&
        <>
          <Fade bottom>
            <h5> 
              {item.fieldColonnaSx}
            </h5>
            <p>
              {item.fieldColonnaDx}
            </p>
          </Fade>
        </>
      }
    </div>
  )
}

export default TwoColumnParagraph
