import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { useStaticQuery, graphql } from "gatsby"
import messages from "../assets/messages.json"

const ContactUs: React.FC = () => {
  const language = useSelector((state: RootState) => state.inner.language)

  const data = useStaticQuery(graphql`
    query {
      customAdminContactsAdminContactsResourceEn {
        ccp
        email
        img
        via
        tel
        stato
        mob
      }
      customAdminContactsAdminContactsResourceIt {
        ccp
        email
        img
        mob
        stato
        tel
        via
      }
    }
  `)
  return (
    <div className="c-contactUs">
      <div className="slide-in">
        <iframe src="https://maps.google.com/maps?q=via%20roma%201%2C%20trieste&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}></iframe>
      </div>
      <div className="slide-in">
        <p className="c-contactUs__title">{messages[language.toLowerCase()].ourShowroom}</p>
        <p>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].via}</p>
        <p>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].ccp}</p>
        <p>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].stato}</p>
        <p className="c-contactUs__title">{messages[language.toLowerCase()].ourContacts}</p>
        <p>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].email}</p>
        <p>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].tel}</p>
        <p>{data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].mob}</p>
      </div>
      <div className="slide-in">
        <img src={data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].img} alt="contact" />
      </div>
    </div>
  )
}

export default ContactUs
