import React from "react"

const ImageCollage: React.FC<any> = (item) => {
  return (
    <div className="c-imageCollage">
      {item && 
        <>
          <div className="slide-in">
            <img src={item.fieldImgSx.url} alt={item.fieldImgSx.alt} />
          </div>
          <div className="slide-in">
            <img src={item.fieldImgCx.url} alt={item.fieldImgCx.alt} />
          </div>
          <div className="slide-in">
            <img src={item.fieldImgDx.url} alt={item.fieldImgDx.alt} />
          </div>
        </>
      }
    </div>
  )
}

export default ImageCollage
