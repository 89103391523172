import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import AboutUs from "../components/about_us"
import TwoColumnParagraph from "../components/two_column_paragraph"
import ImageCollage from "../components/image_collage"
import { graphql } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { setPage } from "../redux/reducers/indexSlice"
import ContactUs from "../components/contact_us"
import BrandList from "../components/brand_list"
import { RootState } from "../redux/store"

const ChiSiamo: React.FC<any> = ({ data }) => {
  const language = useSelector((state: RootState) => state.inner.language)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPage(1))
  }, [])

  return (
    <Layout>
      <SEO title={data.drupal[language.toLowerCase()].title} />
      <Banner
        title={data.drupal[language.toLowerCase()].title}
        image={data.drupal[language.toLowerCase()].fieldImg}
      />
      <AboutUs />
      {
        data.drupal[language.toLowerCase()].fieldContenuti.map(field => {
          if (field.entity.__typename === "Drupal_Paragraph2Colonne") {
            return <TwoColumnParagraph {...field.entity} key={`two-column-paragraph-${field.entity.id}`}/>
          } else if (field.entity.__typename === "Drupal_Paragraph3Foto") {
            return <ImageCollage {...field.entity} key={`image-collage-${field.entity.id}`}/>
          }
        })
      }
      <ContactUs />
      <BrandList hasBackground={false} />
    </Layout>
  )
}

export default ChiSiamo

export const query = graphql`
query {
  drupal {
    en: nodeById(id: "3", language: EN) {
      title
      ... on Drupal_NodePaginaBase {
        nid
        fieldContenuti {
          entity {
            ... on Drupal_Paragraph2Colonne {
              id
              fieldColonnaDx
              fieldColonnaSx
            }
            ... on Drupal_Paragraph3Foto {
              id
              fieldImgCx {
                url
                alt
              }
              fieldImgDx {
                url
                alt
              }
              fieldImgSx {
                url
                alt
              }
            }
          }
        }
        fieldImg {
          url
          alt
        }
      }
    }
    it: nodeById(id: "3", language: IT) {
      title
      ... on Drupal_NodePaginaBase {
        nid
        fieldContenuti {
          entity {
            ... on Drupal_Paragraph2Colonne {
              id
              fieldColonnaDx
              fieldColonnaSx
            }
            ... on Drupal_Paragraph3Foto {
              id
              fieldImgCx {
                url
                alt
              }
              fieldImgDx {
                url
                alt
              }
              fieldImgSx {
                url
                alt
              }
            }
          }
        }
        fieldImg {
          url
          alt
        }
      }
    }
  }
}
`